import Cookies from 'js-cookie';
import { SET_LOCALE } from '../actions/types';

const INITIAL_STATE = Cookies.get('lang') || null;
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.payload;
    default:
      return state;
  }
};
