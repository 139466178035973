/* eslint-disable implicit-arrow-linebreak */
import {
  SET_MULTIPLE_SELECT,
  SET_POLYGON_DRAW,
  ADD_SELECTION_MULTIPLE,
  DELETE_SELECTION_MULTIPLE,
  SET_SELECTION_POLYGON,
  REMOVE_SELECTIONS,
  SET_COMPLETE_SELECTION,
  SET_NEW_SEQUENCE,
  REDRAW_ROUTES,
  REDRAW_ROUTES_WITH_RESET,
  SET_SELECT_ALL_ROUTE,
  CHANGE_MARKER_STATUS,
  SET_MANUEL_SELECT,
  SET_STREET_VIEW,
  SET_USE_STOP_SEQUENCE,
} from '../actions/types';

const INITIAL_STATE = {
  polygonDraw: false,
  multipleSelect: false,
  manuelSelect: false,
  completeSelection: false,
  newSequence: null,
  redraw: false,
  changed_status: [],
  selectAllRoute: false,
  selectedOrders: {
    withPolygon: [],
    withPolygonMarkers: [],
    withMultiple: [],
    withMultipleMarkers: [],
  },
  streetView: true,
  useStopSequence: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_POLYGON_DRAW:
      return {
        ...state,
        polygonDraw: action.payload,
        multipleSelect: false,
        selectAllRoute: false,
        manuelSelect: false,
      };
    case SET_MULTIPLE_SELECT:
      return {
        ...state,
        multipleSelect: action.payload,
        polygonDraw: false,
        selectAllRoute: false,
        manuelSelect: false,
      };
    case SET_MANUEL_SELECT:
      return {
        ...state,
        manuelSelect: action.payload,
        polygonDraw: false,
        selectAllRoute: false,
        multipleSelect: false,
      };
    case SET_SELECT_ALL_ROUTE: {
      return {
        ...state,
        selectAllRoute: action.payload,
        polygonDraw: false,
        manuelSelect: false,
        multipleSelect: false,
      };
    }
    case ADD_SELECTION_MULTIPLE: {
      const { orderGroup, marker } = action.payload;
      const selectedOrdersTemp = { ...state.selectedOrders };
      const { withMultiple, withMultipleMarkers } = selectedOrdersTemp;
      selectedOrdersTemp.withMultiple = [...withMultiple, ...orderGroup];
      selectedOrdersTemp.withMultipleMarkers = [...withMultipleMarkers, marker];
      return { ...state, selectedOrders: selectedOrdersTemp };
    }
    case DELETE_SELECTION_MULTIPLE: {
      const indices = action.payload;
      const selectedOrdersTemp = { ...state.selectedOrders };
      const { withMultiple, withMultipleMarkers } = selectedOrdersTemp;
      const markersDeletedIndices = [];
      withMultipleMarkers.forEach((m, ind) => {
        const { order } = m.getData();
        indices.forEach((i) => {
          if (withMultiple[i].OrderID === order[0].OrderID) {
            markersDeletedIndices.push(ind);
          }
        });
      });
      const indexSet = new Set(indices);
      const newWithMultiple = withMultiple.filter((value, i) => !indexSet.has(i));
      const indexSetMarkers = new Set(markersDeletedIndices);
      const newWithMultipleMarkers = withMultipleMarkers.filter(
        (value, i) => !indexSetMarkers.has(i),
      );

      return {
        ...state,
        selectedOrders: {
          ...state.selectedOrders,
          withMultipleMarkers: newWithMultipleMarkers,
          withMultiple: newWithMultiple,
        },
      };
    }
    case SET_SELECTION_POLYGON: {
      const { selectedOrders, ordersToDelete, polygonMarkers } = action.payload;
      const selectedOrdersTemp = { ...state.selectedOrders };
      let { withPolygon } = selectedOrdersTemp;
      selectedOrders.forEach((order) => {
        if (
          !withPolygon.find(
            (item) =>
              item.OrderID === order.OrderID &&
              parseInt(item.Sequence, 10) === parseInt(order.Sequence, 10),
          )
        ) {
          withPolygon = [...withPolygon, order];
        }
      });
      ordersToDelete.forEach((order) => {
        const foundIndex = withPolygon.findIndex((item) => item.OrderID === order.OrderID);
        if (foundIndex >= 0) {
          withPolygon.splice(foundIndex, 1);
        }
      });
      return {
        ...state,
        selectedOrders: {
          ...state.selectedOrders,
          withPolygon: [...withPolygon],
          withPolygonMarkers: polygonMarkers,
        },
      };
    }
    case REMOVE_SELECTIONS: {
      return {
        ...state,
        polygonDraw: false,
        multipleSelect: false,
        completeSelection: false,
        newSequence: null,
        redraw: false,
        selectAllRoute: false,
        selectedOrders: {
          withPolygon: [],
          withPolygonMarkers: [],
          withMultiple: [],
          withMultipleMarkers: [],
        },
        streetView: true,
        useStopSequence: false,
      };
    }
    case SET_COMPLETE_SELECTION:
      return {
        ...state,
        completeSelection: action.payload,
        polygonDraw: false,
        multipleSelect: false,
      };
    case SET_NEW_SEQUENCE: {
      return {
        ...state,
        polygonDraw: false,
        multipleSelect: false,
        completeSelection: false,
        newSequence: action.payload,
      };
    }
    case REDRAW_ROUTES:
      return {
        ...state,
        redraw: action.payload,
      };
    case REDRAW_ROUTES_WITH_RESET:
      return {
        ...state,
        polygonDraw: false,
        multipleSelect: false,
        completeSelection: false,
        newSequence: null,
        redraw: action.payload,
        selectAllRoute: false,
        selectedOrders: {
          withPolygon: [],
          withPolygonMarkers: [],
          withMultiple: [],
          withMultipleMarkers: [],
        },
        streetView: true,
        useStopSequence: false,
      };
    case CHANGE_MARKER_STATUS:
      return {
        ...state,
        changed_status: action.payload,
      };
    case SET_STREET_VIEW:
      return {
        ...state,
        streetView: action.payload,
      };
    case SET_USE_STOP_SEQUENCE:
      return {
        ...state,
        useStopSequence: action.payload,
      };
    default:
      return state;
  }
};
