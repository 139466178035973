/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import {
  SET_PLAN_CHANGED,
  RESET_PLAN_MODIFICATIONS,
  UNDO_MODIFICATION,
  SET_CHANGED_ORDERS,
  SET_CHANGED_LOCATIONS,
  SET_CHANGED_VEHICLES,
  SET_PLAN_CHANGE_ERROR,
  SET_AVOID_AREA_ADDED,
  HARD_RESET_PLAN_MODIFICATIONS,
} from '../actions/types';

const INITIAL_STATE = {
  isPlanChanged: false,
  isNewAvoidAreaAdded: false,
  planChangeError: false,
  numberOfChanges: 0,
  modificationSteps: [],
  changedOrders: [],
  changedLocations: [],
  changedVehicles: [],
  history: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PLAN_CHANGE_ERROR: {
      return {
        ...state,
        planChangeError: false,
      };
    }
    case SET_PLAN_CHANGED: {
      const { prev, curr, history } = action.payload;
      const lookup = curr.routeDetails.reduce((a, e) => {
        a[`${e.OrderID}-${e.ServiceType}`] = ++a[`${e.OrderID}-${e.ServiceType}`] || 0;
        return a;
      }, {});
      const duplicates = curr.routeDetails.filter((e) => lookup[`${e.OrderID}-${e.ServiceType}`]);
      const duplicatesWithoutStartEnd = duplicates.filter(
        (e) => e.OrderID !== 'Start' && e.OrderID !== 'End',
      );
      return {
        ...state,
        ...(duplicatesWithoutStartEnd.length > 0 && { planChangeError: true }),
        isPlanChanged: true,
        numberOfChanges: state.numberOfChanges + 1,
        modificationSteps: [
          ...state.modificationSteps,
          {
            prev: {
              ...prev,
              ...(prev.effectedRoutes && {
                effectedRoutes: prev.effectedRoutes.filter((v, i, a) => a.indexOf(v) === i),
              }),
            },
            curr,
          },
        ],
        history: [...state.history, history],
      };
    }
    case UNDO_MODIFICATION: {
      const afterUndo = state.numberOfChanges - 1;
      const tempSteps = [...state.modificationSteps];
      const tempHistory = [...state.history];
      tempSteps.splice(tempSteps.length - 1, 1);
      tempHistory.pop();
      if (afterUndo === 0) {
        return {
          ...state,
          planChangeError: false,
          isPlanChanged: false,
          numberOfChanges: 0,
          modificationSteps: [],
          changedOrders: [],
          changedLocations: [],
          changedVehicles: [],
          history: [],
        };
      }
      return {
        ...state,
        changedOrders: state.changedOrders.filter((item) => item.step - 1 < afterUndo),
        changedLocations: state.changedLocations.filter((item) => item.step - 1 < afterUndo),
        changedVehicles: state.changedVehicles.filter((item) => item.step - 1 < afterUndo),
        numberOfChanges: afterUndo,
        modificationSteps: tempSteps,
        history: tempHistory,
      };
    }
    case SET_CHANGED_VEHICLES:
      return { ...state, changedVehicles: [...state.changedVehicles, ...action.payload] };
    case SET_CHANGED_ORDERS:
      return { ...state, changedOrders: [...state.changedOrders, ...action.payload] };
    case SET_CHANGED_LOCATIONS:
      return { ...state, changedLocations: [...state.changedLocations, ...action.payload] };
    case RESET_PLAN_MODIFICATIONS:
      return {
        ...state,
        isPlanChanged: false,
        planChangeError: false,
        numberOfChanges: 0,
        modificationSteps: [],
        changedOrders: [],
        changedLocations: [],
        changedVehicles: [],
        history: [],
      };
    case HARD_RESET_PLAN_MODIFICATIONS:
      return INITIAL_STATE;
    case SET_AVOID_AREA_ADDED:
      return { ...state, isNewAvoidAreaAdded: true };
    default:
      return state;
  }
};
