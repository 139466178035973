import {
  SET_FORM_VALUES,
  SET_FORM_ADDRESS,
  ADD_NEW_FORM_VALUES,
  SET_FORM_WITH_EDIT,
  SET_FORM_WITH_EDIT_NEW_PLAN,
  SET_FORM_WITH_CLONE,
} from '../actions/types';

const INITIAL_STATE = {
  formValues: {},
  openedWith: 'empty',
};
// Mart
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FORM_VALUES:
      return { formValues: { ...action.payload }, openedWith: 'empty' };
    case SET_FORM_ADDRESS:
      return { ...state, formValues: { ...state.formValues, locationAddress: action.payload } };
    case ADD_NEW_FORM_VALUES:
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    case SET_FORM_WITH_EDIT:
      return { formValues: { ...action.payload }, openedWith: 'edit' };
    case SET_FORM_WITH_EDIT_NEW_PLAN:
      return { formValues: { ...action.payload }, openedWith: 'editNewPlan' };
    case SET_FORM_WITH_CLONE:
      return { formValues: { ...action.payload }, openedWith: 'clone' };
    default:
      return state;
  }
};
