/* eslint-disable object-curly-newline */
import React from 'react';
import Lottie from 'react-lottie-player';
import LogoAnimation from './logo_loading_splash.json';

const LoadingAnimation = ({ width, height, isLoading, styles, text, absolute }) => (
  <div>
    {isLoading && (
      <div
        className="blocker d-flex justify-center align-center"
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          position: absolute ? 'absolute' : 'fixed',
          zIndex: 99999999,
        }}
      >
        <Lottie
          loop
          play
          animationData={LogoAnimation}
          style={{ width: width || 80, height: height || 75, ...styles }}
        />
        <span className="text-24 ml-20 text-white">{text}</span>
      </div>
    )}
  </div>
);

export default LoadingAnimation;
