/* eslint-enable import/no-unresolved */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: [
    'auth',
    'locale',
    'toast',
    'company',
    'tables',
    'form',
    'savedFilters',
    'rbac',
    'userPreferences',
  ],
};
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
// creates the storea
export default (rootReducer) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  middleware.push(thunk);

  /* ------------- Assemble Middleware ------------- */
  enhancers.push(applyMiddleware(...middleware));
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  const createAppropriateStore = createStore;
  const store = createAppropriateStore(persistedReducer, {}, composeEnhancers(...enhancers));

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
