import configureStore from './reducers/CreateStore';
import reducers from './reducers';

/* ------------- Assemble The Reducers ------------- */

const createStore = () => {
  const { store, persistor } = configureStore(reducers);

  if (module.hot) {
    module.hot.accept(() => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('.').reducers;
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
};

const { store, persistor } = createStore();
export { store, persistor };
