/* eslint-disable consistent-return */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
import { lazy } from 'react';

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem('page-has-been-force-refreshed') || 'false');

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      console.log(error);
      throw error;
    }
  });
const Login = lazyWithRetry(() => import('./views/auth/index'));
const Password = lazyWithRetry(() => import('./views/auth/password'));

const PlanDashboard = lazyWithRetry(() => import('./views/dashboard/planDashboard'));
const TrackDashboard = lazyWithRetry(() => import('./views/dashboard/trackDashboard'));
const DeliveryDashboard = lazyWithRetry(() => import('./views/dashboard/delivery'));
const CourierDashboard = lazyWithRetry(() => import('./views/dashboard/courier'));

const Planning = lazyWithRetry(() => import('./views/planning'));
const SinglePlan = lazyWithRetry(() => import('./views/planning/singlePlan/singlePlan'));
const NewPlan = lazyWithRetry(() => import('./views/planning/newPlan'));

const CreatePlan = lazyWithRetry(() => import('./views/createPlan'));

const Tracking = lazyWithRetry(() => import('./views/tracking'));
const OrderTracking = lazyWithRetry(() => import('./views/orderTracking'));
const CourierTrack = lazyWithRetry(() => import('./views/courier/track'));
const Shifts = lazyWithRetry(() => import('./views/courier/shifts'));

const PlanningReports = lazyWithRetry(() => import('./views/reports/planningReports'));
const RouteReports = lazyWithRetry(() => import('./views/reports/route'));
const CourierReports = lazyWithRetry(() => import('./views/reports/courier'));
const Consensus = lazyWithRetry(() => import('./views/reports/agreement'));
const PlanReport = lazyWithRetry(() => import('./views/reports/reportsView/planReport'));
const PermissionRequest = lazyWithRetry(() => import('./views/permissionRequest'));
const HereAccessProblem = lazyWithRetry(() => import('./views/permissionRequest/hereAccessProblem'));

const AvoidAreasSetting = lazyWithRetry(() => import('./views/settings/avoidAreaSettings/index'));
const TrackAppSettings = lazyWithRetry(() => import('./views/settings/trackApp/index'));
const MobileAppGeneralSettings = lazyWithRetry(() => import('./views/settings/mobileAppSettings/generalSettings'));
const RequiredFieldsSettings = lazyWithRetry(() => import('./views/settings/mobileAppSettings/requiredFieldSettings'));
const GeneralSettingsApplication = lazyWithRetry(() => import('./views/settings/application/generalSettings'));

const LableSettings = lazyWithRetry(() => import('./views/settings/application/labelSettings'));

const Logout = lazyWithRetry(() => import('./views/logout'));
const ApiDocs = lazyWithRetry(() => import('./views/apiDocs'));
const NotFound = lazyWithRetry(() => import('./views/notfound'));
const Drivers = lazyWithRetry(() => import('./views/masterData/drivers'));
const Parameters = lazyWithRetry(() => import('./views/masterData/parameters'));
const AddParameter = lazyWithRetry(() => import('./views/masterData/parameters/addParameter'));
const UnplannedOrders = lazyWithRetry(() => import('./views/orders/unplannedOrders'));
const PlannedOrders = lazyWithRetry(() => import('./views/orders/plannedOrders'));
const DeletedOrders = lazyWithRetry(() => import('./views/orders/deletedOrders'));

const AddOrder = lazyWithRetry(() => import('./views/orders/add/addOrder'));
const DataUpload = lazyWithRetry(() => import('./views/masterData/dataUpload/excel/excel'));
const DataUploadPrn = lazyWithRetry(() => import('./views/masterData/dataUpload/prn/index'));

const Polygons = lazyWithRetry(() => import('./views/masterData/polygons'));
const AddPolygon = lazyWithRetry(() => import('./views/masterData/polygons/addPolygon'));
const Regions = lazyWithRetry(() => import('./views/masterData/regions'));
const AddRegion = lazyWithRetry(() => import('./views/masterData/regions/addRegion'));
const Locations = lazyWithRetry(() => import('./views/masterData/locations'));
const AddLocation = lazyWithRetry(() => import('./views/masterData/locations/addLocation'));
const FixLocation = lazyWithRetry(() => import('./views/masterData/locations/fixLocation'));
const StockKeepingUnit = lazyWithRetry(() => import('./views/masterData/stock'));
const AddStockKeepingUnit = lazyWithRetry(() => import('./views/masterData/stock/addStock'));
const Pallets = lazyWithRetry(() => import('./views/masterData/pallets'));
const AddPallets = lazyWithRetry(() => import('./views/masterData/pallets/addPallet'));
const transportationCost = lazyWithRetry(() => import('views/masterData/transportationCost'));
const AddTransportationCost = lazyWithRetry(() => import('views/masterData/transportationCost/addTransportationCost'));
const Vehicles = lazyWithRetry(() => import('./views/masterData/vehicles'));
const AddVehicle = lazyWithRetry(() => import('./views/masterData/vehicles/addVehicle'));

const CreateAccount = lazyWithRetry(() => import('./views/auth/createAccount/createAccount'));
const ForgotPassword = lazyWithRetry(() => import('./views/auth/forgotPassword/forgotPassword'));
const ForgotReset = lazyWithRetry(() => import('./views/auth/forgotPassword/passwordReset'));
const PasswordReset = lazyWithRetry(() => import('./views/auth/passwordReset'));
const TwoFAEnable = lazyWithRetry(() => import('./views/masterData/drivers/2faEnable'));
const MonthlyRentalCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/monthlyRentalCost'));
const DailyRentalCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/dailyRentalCost'));
const ReturnCollectionCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/returnCollectionCost'));
const DistanceBasedCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/distanceBasedCost'));
const AdditionalTourCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/additionalTourCost'));
const TaskBasedCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/taskBasedCost'));
const OtherCost = lazyWithRetry(() => import('./views/masterData/progressPaymentCosts/otherCost'));
const ExternalRouteOperations = lazyWithRetry(() => import('./views/externalRouteOperations/externalRouteOperations'));
const TransferOrderActions = lazyWithRetry(() => import('./views/externalRouteOperations/transferOrderActions'));

const PropertiesSetting = lazyWithRetry(() => import('./views/settings/application/propertiesSetting'));
const ScheduledTaskSetting = lazyWithRetry(() => import('./views/settings/application/scheduledTasks'));
const SystemSettings = lazyWithRetry(() => import('./views/settings/system'));
const ProgressPaymentWizard = lazyWithRetry(() => import('./views/progressPayment/progressPaymentWizard/index'));
const SingleCompensationReport = lazyWithRetry(() => import('./views/progressPayment/singleReport/index'));

const CompanyUsers = lazyWithRetry(() => import('./views/admin/companyUsers/index'));
const CompanyOwners = lazyWithRetry(() => import('./views/admin/companyOwner/index'));
const CompanyRoles = lazyWithRetry(() => import('./views/admin/companyRoles/index'));
const PermissionGroups = lazyWithRetry(() => import('./views/admin/permissionGroups/index'));

const routes = [
  {
    path: '/settings',
    permissionID: 'Settings',
    component: AvoidAreasSetting,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/avoid_area',
    permissionID: 'MapAvoidAreaSettings',
    component: AvoidAreasSetting,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/application/general-settings',
    permissionID: 'GeneralApplicationSettings',
    component: GeneralSettingsApplication,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/application/property-settings',
    permissionID: 'MasterDataPropertiesSettings',
    component: PropertiesSetting,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/application/scheduled-task-settings',
    permissionID: 'ScheduledTask',
    component: ScheduledTaskSetting,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/application/group-settings',
    permissionID: 'GroupSettings',
    component: LableSettings,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/system',
    permissionID: 'SystemSettings',
    component: SystemSettings,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/mobile/general-settings',
    permissionID: 'GeneralMobileAppSettings',
    component: MobileAppGeneralSettings,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/mobile/required-fields-settings',
    permissionID: 'RequiredFieldSettings',
    component: RequiredFieldsSettings,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/settings/trackApp',
    permissionID: 'TrackingAppSettings',
    component: TrackAppSettings,
    isPrivate: true,
    exact: true,
  },

  {
    path: '/planning',
    permissionID: 'Planning',
    component: Planning,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/plan_dashboard',
    permissionID: 'PlanningPerformance',
    component: PlanDashboard,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/summary_dashboard',
    permissionID: 'FieldPerformance',
    component: TrackDashboard,
    isPrivate: true,
  },
  {
    path: '/delivery_dashboard',
    permissionID: 'DetailedAnalysis',
    component: DeliveryDashboard,
    isPrivate: true,
  },
  {
    path: '/courier_dashboard',
    permissionID: 'CourierDashboard',
    component: CourierDashboard,
    isPrivate: true,
  },
  {
    path: '/planning/:number',
    permissionID: 'Planning',
    component: SinglePlan,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/tracking',
    permissionID: 'Tracking',
    component: Tracking,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/courier-tracking',
    permissionID: 'CourierTracking',
    component: CourierTrack,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/shift-management',
    component: Shifts,
    permissionID: 'ShiftManagement',
    isPrivate: true,
    exact: true,
  },
  {
    path: '/order_tracking',
    permissionID: 'OrderTracking',
    component: OrderTracking,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/drivers',
    permissionID: 'Drivers',
    component: Drivers,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/planning_reports',
    permissionID: 'PlanningReports',
    component: PlanningReports,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/reports/:number',
    permissionID: 'PlanningReports',
    component: PlanReport,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/route_reports',
    permissionID: 'RouteReports',
    component: RouteReports,
    isPrivate: true,
  },
  {
    path: '/consensus',
    permissionID: 'ConsensusReports',
    component: Consensus,
    isPrivate: true,
  },
  {
    path: '/courier_reports',
    permissionID: 'CourierReports',
    component: CourierReports,
    isPrivate: true,
  },
  {
    path: '/off_route_actions',
    permissionID: 'ExternalRouteActions',
    component: ExternalRouteOperations,
    isPrivate: true,
  },
  {
    path: '/transfer_order_actions',
    permissionID: 'ExternalRouteActions' /* XXX */,
    component: TransferOrderActions,
    isPrivate: true,
  },
  {
    path: '/parameters',
    permissionID: 'Parameters',
    component: Parameters,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/parameters/:number',
    permissionID: 'AddParameterSet',
    component: AddParameter,
    isPrivate: true,
    exact: true,
  },

  {
    path: '/orders/new',
    permissionID: 'AddNewOrder-Orders',
    component: AddOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/orders/new/free',
    permissionID: 'AddNewOrder-Orders',
    component: AddOrder,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/unplanned-orders',
    permissionID: 'UnplannedOrders',
    component: UnplannedOrders,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/planned-orders',
    permissionID: 'PlannedOrders',
    component: PlannedOrders,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/deleted-orders',
    permissionID: 'DeletedOrders',
    component: DeletedOrders,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/transportation-cost',
    permissionID: 'TransportationCost',
    component: transportationCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/transportation-cost/new',
    permissionID: 'AddNewTransportationCost',
    component: AddTransportationCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/monthlyRentalCost',
    permissionID: 'MonthlyRentalCost',
    component: MonthlyRentalCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/dailyRentalCost',
    permissionID: 'DailyRentalCost',
    component: DailyRentalCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/returnCollectionCost',
    permissionID: 'ReturnCollectionCost',
    component: ReturnCollectionCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/distanceBasedCost',
    permissionID: 'DistanceBasedCost',
    component: DistanceBasedCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/additionalTourCost',
    permissionID: 'AdditionalTourCost',
    component: AdditionalTourCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/otherCost',
    permissionID: 'OtherCost',
    component: OtherCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation/taskBasedCost',
    permissionID: 'TaskBasedCost',
    component: TaskBasedCost,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/locations',
    permissionID: 'Locations',
    component: Locations,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/stock',
    permissionID: 'StockKeepingUnit',
    component: StockKeepingUnit,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/pallets',
    permissionID: 'Pallet',
    component: Pallets,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/polygons',
    permissionID: 'Polygons',
    component: Polygons,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/polygons/new',
    permissionID: 'AddNewPolygon',
    component: AddPolygon,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/regions',
    permissionID: 'Regions',
    component: Regions,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/regions/new',
    permissionID: 'AddNewRegion',
    component: AddRegion,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/stock/new',
    permissionID: 'Add-StockKeepingUnit',
    component: AddStockKeepingUnit,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/pallets/new',
    permissionID: 'Add-Pallet',
    component: AddPallets,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/locations/new',
    permissionID: 'AddNewLocation',
    component: AddLocation,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/locations/new/free',
    permissionID: 'AddNewLocation',
    component: AddLocation,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/locations/fix',
    permissionID: 'AddNewLocation',
    component: FixLocation,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/vehicles',
    permissionID: 'Vehicles',
    component: Vehicles,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/vehicles/new',
    permissionID: 'AddNewVehicle',
    component: AddVehicle,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/vehicles/new/free',
    permissionID: 'AddNewVehicle',
    component: AddVehicle,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/new_plan',
    permissionID: 'CreateNewPlanV2',
    component: CreatePlan,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/new_plan_v1',
    permissionID: 'CreateNewPlan',
    component: NewPlan,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/upload-data/excel',
    permissionID: 'UploadWithExcel',
    component: DataUpload,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/upload-data/prn',
    permissionID: 'UploadWithPRN',
    component: DataUploadPrn,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation-reports/new',
    permissionID: 'Create-MonthlyCompensationReport',
    component: ProgressPaymentWizard,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/compensation-reports/:id/',
    permissionID: 'Click-MonthlyCompensationReport',
    component: SingleCompensationReport,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/iam/owners',
    permissionID: 'IAM-Users',
    component: CompanyOwners,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/iam/users',
    permissionID: 'IAM-Users',
    component: CompanyUsers,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/iam/groups',
    permissionID: 'IAM-Users',
    component: PermissionGroups,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/iam/roles',
    permissionID: 'IAM-Roles',
    component: CompanyRoles,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/',
    component: Login,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/here_access',
    component: HereAccessProblem,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/permission_request',
    component: PermissionRequest,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/auth/password',
    component: Password,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/logout',
    component: Logout,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/api',
    component: ApiDocs,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/create-account',
    component: CreateAccount,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/forgot-password-email',
    component: ForgotPassword,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotReset,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/two-factor/confirm/:uid/:token',
    component: TwoFAEnable,
    isPrivate: false,
    exact: true,
  },
  {
    component: NotFound,
    isPrivate: false,
    exact: false,
  },
];

export default routes;
