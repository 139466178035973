import { SET_SERVICE_WORKER_UPDATED } from '../actions/types';

const INITIAL_STATE = {
  waitingServiceWorker: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SERVICE_WORKER_UPDATED: {
      const { waitingServiceWorker } = action.payload;
      return { ...state, waitingServiceWorker };
    }
    default:
      return state;
  }
};
