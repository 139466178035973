/* eslint-disable no-nested-ternary */
import { getAuth } from '@utils/api';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const GuestRoute = ({ component, path, exact }) => {
  const authorization = getAuth();
  if (!path) return <Redirect to="/" />;
  if (path && path.includes('logout')) return <Route exact={exact} path={path} component={component} />;
  if (!authorization || (path && path.includes('/two-factor/confirm/'))) {
    return <Route exact={exact} path={path} component={component} />;
  }
  return <Redirect to="/planning" />;
};

export default GuestRoute;
