/* eslint-disable */
import { getAuth } from '@utils/api';
import React from 'react';
import * as Sentry from '@sentry/browser';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from 'routes';
import { InstantDeliveryPermissionIds, OrderItemPermissionIds } from '@utils/constants';

const routeToLookFor = {
  Dashboard: [
    'PlanningPerformance',
    'FieldPerformance',
    'DetailedAnalysis',
    'CancellationDistribution',
    'ServiceTimes',
    'OnTimePerformance',
    'RouteComplianceScores',
  ],
  Reports: ['PlanningReports', 'RouteReports', 'RouteStatusReports', 'DistanceReports', 'ConsensusReports'],
  Orders: ['UnplannedOrders', 'PlannedOrders', 'DeletedOrders'],
  Compensation: ['MonthlyRentalCost', 'DailyRentalCost', 'DistanceBasedCost', 'AdditionalTourCost', 'TaskBasedCost', 'OtherCost'],
  Settings: [
    'Settings',
    'MapAvoidAreaSettings',
    'GeneralApplicationSettings',
    'MasterDataPropertiesSettings',
    'ScheduledTask',
    'GroupSettings',
    'SystemSettings',
    'GeneralMobileAppSettings',
    'RequiredFieldSettings',
    'TrackingAppSettings',
  ],
};

const PrivateRoute = ({ children, component, path, exact, permissionID }) => {
  const authorization = getAuth();
  const rbac = useSelector((state) => state.rbac);
  const settings = useSelector((state) => state.company.settings);
  const getSettingsPermission = (name) => {
    try {
      const permissionIdsToLook = [...InstantDeliveryPermissionIds, ...OrderItemPermissionIds];
      if (permissionIdsToLook.includes(name)) {
        if (!settings?.operationType) {
          return false;
        }
        const type = JSON.parse(settings.operationType);
        if (type.instantDelivery && InstantDeliveryPermissionIds.includes(name)) {
          return true;
        }
        if (type.orderItem && OrderItemPermissionIds.includes(name)) {
          return true;
        }
        return false;
      }
      return true;
    } catch (e) {
      return true;
    }
  };
  if (authorization) {
    Sentry.setUser({
      userID: authorization.user_id,
      email: `${authorization.first_name} ${authorization.last_name}`,
    });

    // FIND an available path at start.
    const pages = Object.keys(rbac?.screens || {})?.filter((s) => rbac.screens[s].permissions.view);
    let availablePathToGo = null;
    if (pages?.length > 0) {
      // İzni olan herhangi bir sayfa varsa
      for (const p of pages) {
        // Uygun olan ilk pathi bul
        availablePathToGo = routes.find((r) => r.permissionID === p)?.path;
        if (availablePathToGo) break;
      }
    }

    // Permission from settings. DELETE AFTER COMPANY PROFILE STRUCTURE
    const settingsPermission = getSettingsPermission(permissionID);
    if (!settingsPermission) {
      if (pages?.length > 0) {
        // İzni olan herhangi bir sayfa varsa
        if (availablePathToGo) return <Redirect to={availablePathToGo} />;
        // Uygun olan bir sayfa bulunamadıysa
        return <Redirect to="/permission_request" />;
      }
    }

    if (!permissionID) {
      if (path === '/permission_request' && availablePathToGo) {
        return <Redirect to={availablePathToGo} />;
      }
      return <Route exact={exact} path={path} component={component} />;
    }

    if (rbac?.screens && rbac.screens[permissionID]?.permissions.view) {
      // Gidilen Route izinli.
      return (
        <Route exact={exact} path={path} component={component}>
          {children}
        </Route>
      );
    } else {
      // Gidilen Route'da izin yok. Uygun bir route bulmalı.

      // Birbiri ile bağlantılı olan sayfalar arasında ise
      // bağlantılı olduğu sayfa grubunu bul.
      const foundInRouteFamily = Object.values(routeToLookFor).find((family) => family.includes(permissionID));
      if (foundInRouteFamily) {
        // Böyle bir grup varsa bu grubun içinde neleri görme izni olduğuna bak.
        const pageThatUserHaveViewPermissionInFamily = foundInRouteFamily.filter((page) => {
          const pagePermission = (rbac?.screens || {})[page]?.permissions.view;
          return pagePermission;
        });
        if (pageThatUserHaveViewPermissionInFamily?.length > 0) {
          // Grup içinde görme izni olan bir sayfa varsa rotalar içinden pathini bul.
          const pathToGo = routes.find((r) => r.permissionID === pageThatUserHaveViewPermissionInFamily[0]).path;
          // Bulunan alakalı sayfaya yönlendir.
          return <Redirect to={pathToGo} />;
        }
      }
      if (pages?.length > 0) {
        // İzni olan herhangi bir sayfa varsa
        if (availablePathToGo) return <Redirect to={availablePathToGo} />;
        // Uygun olan bir sayfa bulunamadıysa
        return <Redirect to="/permission_request" />;
      }
      // Uygun olan bir sayfa bulunamadıysa
      return <Redirect to="/permission_request" />;
    }
  }
  return <Redirect to="/logout" />;
};

export default PrivateRoute;
