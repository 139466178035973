import { SET_TABLE_COLUMNS } from '../actions/types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TABLE_COLUMNS: {
      const { tableName, headers } = action.payload;
      return { ...state, [tableName]: headers };
    }
    default:
      return state;
  }
};
