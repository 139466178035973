import { SET_MESSAGE, HIDE_MESSAGE } from '../actions/types';

const INITIAL_STATE = {
  message: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case HIDE_MESSAGE:
      return { message: false };
    default:
      return state;
  }
};
