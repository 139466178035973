/* eslint-disable */

export const localesMapping = {
  'en-US': 'en',
  'tr-TR': 'tr',
  'es-ES': 'es',
  'de-DE': 'de',
  'pt-PT': 'pt',
  'it-IT': 'it',
  'fr-FR': 'fr',
  'ru-RU': 'ru',
  'pl-PL': 'pl',
  'zh-CN': 'en',
  'id-ID': 'id',
  'sv-SV': 'sv',
  'nl-NL': 'nl',
  'bg-BG': 'bg',
  'ro-RO': 'ro',
  'ar-AR': 'ar',
};
export const MAX_ROUTE_COUNT_FOR_PLAN = 50;
export const SEARCH_DEBOUNCE_TIME = 500;
export const InstantDeliveryPermissionIds = [
  'CourierTracking',
  'ShiftManagement',
  'CourierReports',
  'CourierDashboard',
  'CourierActivities',
];
export const OrderItemPermissionIds = ['Pallet', 'StockKeepingUnit'];
