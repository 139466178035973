import {
  SET_ALL_COMPANIES,
  SET_COMPANY,
  SET_COMPANY_REFERENCE_PARAMETER_SET,
  SET_COMPANY_SETTINGS,
  SET_COMPANY_LABELS,
  SET_COMPANY_LOCAL_UNITS,
  SET_COMPANY_AUTHORIZATION_LIST,
  SET_ALL_COMPANY_GROUPS,
  SET_COMPANY_TABLE_RULES,
} from '../actions/types';

const INITIAL_STATE = {
  companyID: null,
  allCompanies: [],
  referenceParameters: [],
  settings: [],
  labels: '',
  localUnits: null,
  tableRules: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return { ...state, companyID: action.payload };
    case SET_ALL_COMPANIES:
      return { ...state, allCompanies: action.payload };
    case SET_ALL_COMPANY_GROUPS:
      return { ...state, companyGroups: action.payload };
    case SET_COMPANY_REFERENCE_PARAMETER_SET:
      return { ...state, referenceParameters: action.payload };
    case SET_COMPANY_SETTINGS:
      return { ...state, settings: action.payload };
    case SET_COMPANY_LABELS:
      return { ...state, labels: action.payload };
    case SET_COMPANY_LOCAL_UNITS:
      return { ...state, localUnits: action.payload };
    case SET_COMPANY_TABLE_RULES:
      return { ...state, tableRules: action.payload };
    case SET_COMPANY_AUTHORIZATION_LIST:
      return { ...state, authorizationList: action.payload };
    default:
      return state;
  }
};
