export const SET_USER = 'SET_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_ALL_COMPANIES = 'SET_ALL_COMPANIES';
export const SET_COMPANY_REFERENCE_PARAMETER_SET = 'SET_COMPANY_REFERENCE_PARAMETER_SET';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';
export const SET_POLYGON_DRAW = 'SET_POLYGON_DRAW';
export const SET_MULTIPLE_SELECT = 'SET_MULTIPLE_SELECT';
export const SET_MANUEL_SELECT = 'SET_MANUEL_SELECT';
export const SET_SELECT_ALL_ROUTE = 'SET_SELECT_ALL_ROUTE';
export const ADD_SELECTION_MULTIPLE = 'ADD_SELECTION_MULTIPLE';
export const SET_STREET_VIEW = 'SET_STREET_VIEW';
export const SET_USE_STOP_SEQUENCE = 'SET_USE_STOP_SEQUENCE';
export const REDRAW_ROUTES = 'REDRAW_ROUTES';
export const REDRAW_ROUTES_WITH_RESET = 'REDRAW_ROUTES_WITH_RESET';
export const DELETE_SELECTION_MULTIPLE = 'DELETE_SELECTION_MULTIPLE';
export const SET_SELECTION_POLYGON = 'SET_SELECTION_POLYGON';
export const REMOVE_SELECTIONS = 'REMOVE_SELECTIONS';
export const SET_COMPLETE_SELECTION = 'SET_COMPLETE_SELECTION';
export const SET_NEW_SEQUENCE = 'SET_NEW_SEQUENCE';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_FORM_ADDRESS = 'SET_FORM_ADDRESS';
export const ADD_NEW_FORM_VALUES = 'ADD_NEW_FORM_VALUES';
export const SET_FORM_WITH_CLONE = 'SET_FORM_WITH_CLONE';
export const SET_FORM_WITH_EDIT = 'SET_FORM_WITH_EDIT';
export const SET_FORM_WITH_EDIT_NEW_PLAN = 'SET_FORM_WITH_EDIT_NEW_PLAN';
export const SET_NEW_PLAN = 'SET_NEW_PLAN';
export const RESET_PLAN = 'RESET_PLAN';
export const RESET_PLAN_SOFT = 'RESET_PLAN_SOFT';
export const ADD_NEW_PLAN_VALUES = 'ADD_NEW_PLAN_VALUES';
export const SET_NEW_PLAN_WITH_EDIT = 'SET_NEW_PLAN_WITH_EDIT';
export const SET_NEW_PLAN_WITH_CLONE = 'SET_NEW_PLAN_WITH_CLONE';
export const ADD_NEW_DATA_TO_PLAN = 'ADD_NEW_DATA_TO_PLAN';
export const SET_PLAN_CHANGE_ERROR = 'SET_PLAN_CHANGE_ERROR';
export const SET_PLAN_CHANGED = 'SET_PLAN_CHANGED';
export const RESET_PLAN_MODIFICATIONS = 'RESET_PLAN_MODIFICATIONS';
export const HARD_RESET_PLAN_MODIFICATIONS = 'HARD_RESET_PLAN_MODIFICATIONS';
export const UNDO_MODIFICATION = 'UNDO_MODIFICATION';
export const SET_CHANGED_ORDERS = 'SET_CHANGED_ORDERS';
export const SET_CHANGED_VEHICLES = 'SET_CHANGED_VEHICLE';
export const SET_CHANGED_LOCATIONS = 'SET_CHANGED_LOCATIONS';
export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';
export const CHANGE_MARKER_STATUS = 'CHANGE_MARKER_STATUS';
export const SET_ACTUAL_ROUTE_VISIBLE = 'SET_ACTUAL_ROUTE_VISIBLE';
export const SET_SERVICE_WORKER_UPDATED = 'SET_SERVICE_WORKER_UPDATED';
export const SET_SAVED_FILTERS = 'SET_SAVED_FILTERS';
export const SET_SHIPPING_LIST_EXPORT_OPTIONS = 'SET_SHIPPING_LIST_EXPORT_OPTIONS';
export const SET_PLANNING_LIST_LAST_FILTER = 'SET_PLANNING_LIST_LAST_FILTER';
export const SET_COMPANY_LABELS = 'SET_COMPANY_LABELS';
export const SET_COMPANY_LOCAL_UNITS = 'SET_COMPANY_LOCAL_UNITS';
export const SET_COMPANY_AUTHORIZATION_LIST = 'SET_COMPANY_AUTHORIZATION_LIST';
export const SET_SAVED_COLUMNS = 'SET_SAVED_COLUMNS';
export const SET_RBAC_DETAILS = 'SET_RBAC_DETAILS';
export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
export const SET_AVOID_AREA_ADDED = 'SET_AVOID_AREA_ADDED';
export const SET_ALL_COMPANY_GROUPS = 'SET_ALL_COMPANY_GROUPS';
export const SET_COMPANY_TABLE_RULES = 'SET_COMPANY_TABLE_RULES';
export const SET_FILTERS_ORDER_AND_VISIBILITY = 'SET_FILTERS_ORDER_AND_VISIBILITY';
