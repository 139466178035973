/* eslint-disable no-unused-vars */
import { SET_RBAC_DETAILS } from '../actions/types';

const reduceArray = (acc, curr) => ({
  ...acc,
  [curr.component_id]: { actions: curr.actions, permissions: curr.permissions },
});
const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RBAC_DETAILS: {
      if (!action.payload) return null;
      const { buttons, menus, screens } = action.payload;
      return {
        ...state,
        menus: menus?.reduce(reduceArray, {}),
        screens: screens?.reduce(reduceArray, {}),
        buttons: buttons?.reduce(reduceArray, {}),
      };
    }

    default:
      return state;
  }
};
