import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import AuthReducer from './auth';
import LocaleReducer from './locale';
import ToastReducer from './toast';
import CompanyReducer from './company';
import MapDrawingReducer from './mapDrawing';
import FormValuesReducer from './form';
import NewPlanReducer from './newPlan';
import PlanModificationsReducer from './planModification';
import TableColumnsReducer from './tableColumns';
import TrackingReducer from './tracking';
import ServiceWorkerReducer from './serviceWork';
import SavedFiltersReducer from './savedFilters';
import SavedColumnsReducer from './savedColumns';
import rbacDetailsReducer from './rbacDetails';
import UserPreferencesReducer from './userPreferences';

const appReducer = combineReducers({
  auth: AuthReducer,
  locale: LocaleReducer,
  toast: ToastReducer,
  company: CompanyReducer,
  mapDrawing: MapDrawingReducer,
  tables: TableColumnsReducer,
  form: FormValuesReducer,
  newPlan: NewPlanReducer,
  planModifications: PlanModificationsReducer,
  tracking: TrackingReducer,
  rbac: rbacDetailsReducer,
  serviceWork: ServiceWorkerReducer,
  savedFilters: SavedFiltersReducer,
  savedColumns: SavedColumnsReducer,
  userPreferences: UserPreferencesReducer,
});

export default (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
