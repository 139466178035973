/* eslint-disable */
import { store as reduxStore } from '../store';
const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
const localeUnits = () => {
  const units = reduxStore.getState().company.localUnits;
  if (units)
    return {
      currency: '',
      dateFormat: 'dd-MM-YYYY',
      measureDistance: '',
      measureVolume: '',
      measureWeight: '',
      timeZone: browserTZ,
      ...units,
    };
  return {
    currency: '',
    dateFormat: 'dd-MM-YYYY',
    measureDistance: 'km',
    measureVolume: '',
    measureWeight: '',
    timeZone: browserTZ,
  };
};

export default localeUnits;
