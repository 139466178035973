import {
  ADD_NEW_PLAN_VALUES,
  SET_NEW_PLAN_WITH_EDIT,
  SET_NEW_PLAN_WITH_CLONE,
  SET_NEW_PLAN,
  ADD_NEW_DATA_TO_PLAN,
  RESET_PLAN,
  RESET_PLAN_SOFT,
} from '../actions/types';

const INITIAL_STATE = {
  formValues: {
    newValues: {
      vehicles: [],
      orders: [],
      locations: [],
      transportationCost: [],
      rules: [],
    },
  },
  openedWith: 'empty',
};
// Mart
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PLAN:
      return {
        formValues: {
          newValues: {
            vehicles: [],
            orders: [],
            locations: [],
            transportationCost: [],
            rules: [],
          },
        },
        openedWith: 'empty',
      };
    case RESET_PLAN_SOFT:
      return {
        formValues: {
          scenario_name: state.formValues.scenario_name,
          operation: state.formValues.operation,
          plannedDate: state.formValues.plannedDate,
          selectedMode: state.formValues.selectedMode,
          newValues: {
            vehicles: [],
            orders: [],
            locations: [],
            transportationCost: [],
            rules: [],
          },
        },
        openedWith: 'edit',
      };
    case SET_NEW_PLAN:
      return { formValues: { ...action.payload }, openedWith: 'empty' };
    case ADD_NEW_PLAN_VALUES:
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    case SET_NEW_PLAN_WITH_EDIT:
      return { formValues: { ...action.payload }, openedWith: 'edit' };
    case SET_NEW_PLAN_WITH_CLONE:
      return { formValues: { ...action.payload }, openedWith: 'clone' };
    case ADD_NEW_DATA_TO_PLAN: {
      const { dataType, value } = action.payload;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          newValues: {
            ...state.formValues.newValues,
            [dataType]: [...state.formValues.newValues[dataType], value],
          },
        },
      };
    }
    default:
      return state;
  }
};
