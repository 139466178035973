/* eslint-disable */
/* eslint-disable implicit-arrow-linebreak */

/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
import moment from 'moment-timezone';
import 'moment/locale/tr';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/nl';
import { localesMapping } from '@utils/constants';
import { store } from '../../store';
import localeUnits from '../../hocs/UnitLabels';

function isValidDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (Number.isNaN(d.getTime())) {
      // date is not valid
      return false;
    }
    // date is valid
    return true;
  }
  // not a date
  return false;
}
//     "timeZone": "Europe/Istanbul"
moment.tz.setDefault('Europe/Istanbul');

const rootFormatter = (date, formatString, timeZone) => {
  const l = store.getState().locale;
  moment.locale(localesMapping[l]);
  const tz = localeUnits().timeZone;
  if (date) {
    let formattedDate;
    if (timeZone) {
      formattedDate = moment(date).tz(tz).format(formatString);
    } else {
      formattedDate = moment.parseZone(date).format(formatString);
    }
    if (formattedDate === 'Invalid date') return '?';
    return formattedDate;
  }
};

// 21 Şubat 2022, 11:25
export const formatDateLongMonthWithTime = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'D MMMM YYYY, HH:mm' : 'MMMM D YYYY, HH:mm', timeZone);

// 02 Şub 2022, 10:04
export const formatDateShortMonthWithTime = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD MMM YYYY, HH:mm' : 'MMM DD YYYY, HH:mm', timeZone);

// 21 Şub 22, 12:20
export const formatDateShortMonthShortYearWithTime = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD MMM YY, HH:mm' : 'MMM DD YY, HH:mm', timeZone);

export const formatDateShortMonthShortYearWithTimeAndSecounds = (date, timeZone = true) =>
  rootFormatter(date, 'YYYY-MM-DD HH:mm:ss', timeZone);

export const formatTimeAndSecounds = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'HH:mm:ss' : 'HH:mm:ss', timeZone);

// 21 Şub 2022
export const formatDateNoTime = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD MMM YYYY' : 'MMM DD YYYY', timeZone);

// 21 Şubat 2022
export const formatDateLongMonthNoTime = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD MMMM YYYY' : 'MMMM DD YYYY', timeZone);

// 11.25
export const formatDateOnlyTimeNoSeconds = (date, timeZone = true) => rootFormatter(date, 'HH:mm', timeZone);

// 21.02.2022
export const formatDateWithDots = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD.MM.YYYY' : 'MM.DD.YYYY', timeZone);

export const formatDateMonthYear = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'MMM YYYY' : 'MMM YYYY', timeZone);

// 21.02, 12:12
export const formatDateWithDotsWithTimeNoYear = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD.MM, HH:mm' : 'MM.DD, HH:mm', timeZone);

// 21-02-2022
export const formatDateWithHyphen = (date, timeZone = true) =>
  rootFormatter(date, localeUnits().dateFormat === 'dd-MM-YYYY' ? 'DD-MM-YYYY' : 'MM-DD-YYYY', timeZone);

// 2022-12-06T00:00:00+0300
export const simplyFormat = (date, timeZone = true) => rootFormatter(date, 'Y-MM-DDTHH:mm:ssZZ', timeZone);
export const simplyFormatWithEndOfDay = (date, timeZone = true) => rootFormatter(date, 'Y-MM-DDT23:59:ssZZ', timeZone);
// 2022-12-06T00:00:00+03:00
export const simplyFormatWithColon = (date, timeZone = true) => rootFormatter(date, 'Y-MM-DDTHH:mm:ssZ', timeZone);
// 2022-12-06T00:00:00
export const simplyFormatWithoutTimezone = (date, timeZone = true) => rootFormatter(date, 'YYYY-MM-DDTHH:mm:ss', timeZone);
// 2022-02-21
export const formatDateWithHyphenReverse = (date, timeZone = true) => rootFormatter(date, 'YYYY-MM-DD', timeZone);

export const formatTimeWindowViolation = (time) => {
  if (!time) return '';
  const violation = time.split(':').map((i) => parseInt(i, 10));
  return {
    ...(violation[0] && { day: violation[0] }),
    ...(violation[1] && { hour: violation[1] }),
    ...(violation[2] && { minute: violation[2] }),
  };
};

// 2022-05-12T13:53:56+03:00
export const formatDateStandart = (date) => {
  const l = store.getState().locale;
  moment.locale(localesMapping[l]);
  if (date) return moment(date).format();
};
export const formatDateStandartWithoutSeconds = (date) => {
  const l = store.getState().locale;
  moment.locale(localesMapping[l]);
  if (date) return moment(date).format('YYYY-MM-DDTHH:mm');
};
export const addDayToDate = (date, dayCount = 1) => {
  const l = store.getState().locale;
  moment.locale(localesMapping[l]);
  if (date) return moment(date).add(dayCount, 'day');
};

export const hourFormatHandle = (h) => {
  if (h) {
    const hPart = h.split(':');
    const hR = [];
    hPart.map((part) => {
      if (part.length === 1) {
        hR.push(part.concat('0'));
      } else {
        hR.push(part);
      }
    });
    return hR.join(':');
  }
  return '';
};
