import {
  SET_COMPANY_LABELS,
  SET_ALL_COMPANIES,
  SET_COMPANY,
  SET_COMPANY_REFERENCE_PARAMETER_SET,
  SET_COMPANY_SETTINGS,
  SET_COMPANY_LOCAL_UNITS,
  SET_COMPANY_AUTHORIZATION_LIST,
  SET_ALL_COMPANY_GROUPS,
  SET_COMPANY_TABLE_RULES,
} from './types';

export const setCompany = (data) => ({
  type: SET_COMPANY,
  payload: data,
});

export const setAllCompanies = (companies) => ({
  type: SET_ALL_COMPANIES,
  payload: companies,
});
export const setAllCompanyGroups = (companyLabels) => ({
  type: SET_ALL_COMPANY_GROUPS,
  payload: companyLabels,
});
export const setCompanyReferenceParameterSet = (parameterSet) => ({
  type: SET_COMPANY_REFERENCE_PARAMETER_SET,
  payload: parameterSet,
});

export const setCompanySettings = (settings) => ({
  type: SET_COMPANY_SETTINGS,
  payload: settings,
});

export const setCompanyLabels = (labels) => ({
  type: SET_COMPANY_LABELS,
  payload: labels,
});

export const setCompanyLocalUnits = (localUnits) => ({
  type: SET_COMPANY_LOCAL_UNITS,
  payload: localUnits,
});

export const setCompanyTableRules = (rules) => ({
  type: SET_COMPANY_TABLE_RULES,
  payload: rules,
});

export const setAuthorizationListRedux = (authorizationList) => ({
  type: SET_COMPANY_AUTHORIZATION_LIST,
  payload: authorizationList,
});
