import { SET_SAVED_COLUMNS } from '../actions/types';

const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SAVED_COLUMNS: {
      const { page, values } = action.payload;
      return { ...state, [page]: { ...state[page], [values.name]: values.list } };
    }
    default:
      return state;
  }
};
